
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  watch,
} from "vue";
import Dropdown from "@/components/atomics/dropdown/BaseDropdown.vue";
import { CmindWizRepository, RepositoryFactory } from "@/lib/https";
import { formatDate } from "@/lib/utility/dateTime";
import { useI18n } from "vue-i18n";
import AfterLoginPage from "@/views/layouts/AfterLoginAdminPage.vue";
import ListItemActionDropdown from "@/components/atomics/list-item/ListItemActionDropdown.vue";
import { downloadPDF } from "@/lib/utility/downloadFile";
import BreadScrum from "@/components/atomics/BreadScrum.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "Cmind",
  components: {
    Dropdown,
    AfterLoginPage,
    ListItemActionDropdown,
    BreadScrum,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const partnerCompanyID = route.query.partnerID;

    const state = reactive({
      years: [] as number[],
      currentYear: 0,
      entries: [] as {
        name: number;
        time: string;
        filePath: string;
        cvsFilePath: string;
        createdAt: string;
      }[],
      breadScrumLinks: [] as { text: string; to: string }[],
    });

    const actionDropdown = (index: number) => [
      {
        action: () => {
          handleDownloadInvoice(index);
        },
        icon: "icons/download.svg",
        text: t("cmind.optionDownloadPaymentNotice"),
      },
    ];

    const { getInvoices } =
      RepositoryFactory.getRepository<CmindWizRepository>(CmindWizRepository);

    onMounted(async () => {
      const invoices = await getInvoices("c-mind");
      state.entries = invoices.adminInvoices;
      state.years = invoices.years;
      state.currentYear = state.years[0];

      state.breadScrumLinks = [
        {
          text: t("partner.ListPartner"),
          to: "/partner",
        },
        {
          text: t("cmind.cmind"),
          to: "/partner/" + partnerCompanyID,
        },
        {
          text: t("partner.paymentNotification"),
          to: "/cmind/",
        },
      ];
    });

    const handleDownloadInvoice = (index: number) => {
      downloadPDF(
        process.env.VUE_APP_API_CLOUD_URL + state.entries[index].filePath
      );
    };

    const handleChangeYear = async (index: number) => {
      const { getInvoices } =
        RepositoryFactory.getRepository<CmindWizRepository>(CmindWizRepository);
      const payload = await getInvoices("c-mind", index.toString());
      state.entries = payload.adminInvoices;
    };

    watch(() => state.currentYear, handleChangeYear);

    return {
      ...toRefs(state),
      formatDate,
      actionDropdown,
    };
  },
});
