import axios from "axios";

export function downloadZipFile(url: string): void {
  const link = document.createElement("a");
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function downloadPDF(url: string): void {
  const filename = url.split("/").pop();
  if (!filename) return;

  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.click();
}

export function downloadCSV(fileContent: any, filename: string): void {
  const blob = new Blob(["\ufeff" + fileContent], {
    type: "text/csv; charset=utf-8",
  });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
  URL.revokeObjectURL(link.href);
}
